import React from "react";
import { Navigate, Outlet, Route, useLocation } from "react-router-dom";
import { getCredentials } from "../Services/authService";

const ProtectedAdminRoute = ({ element: Element, ...rest }) => {
  const location = useLocation();
  getCredentials().then((r) => {
    let tokenKey2 = `msal.${r.Client_ID}.idtoken`;
    let tokenKey = `visit-vois-token`;
    let token = sessionStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey2); // Replace this with your authentication logic

    debugger;
    if (!token && window.location.pathname !== "/admin/home") {
      return <Navigate replace='/admin/login' />;
    }
  });
  return <Outlet />;
};

export default ProtectedAdminRoute;
