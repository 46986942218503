import axios from "axios";

import CryptoJS from "crypto-js";
import {
  fetchencryptionKeys,
  getCredentials,
  getCurrentUser,
  getJwt,
} from "./authService";
import { getIdToken } from "../AdminLogin/Auth/authProvider";

export async function generateTokenFingerprint() {
  try {
    const decodedToken = await getCurrentUser();
    const payloadStr = JSON.stringify(decodedToken);
    const hash = CryptoJS.SHA256(payloadStr).toString(CryptoJS.enc.Hex);
    return hash;
  } catch (error) {
    console.error("Error generating token fingerprint:", error);
    return null;
  }
}

const encryptedToken = sessionStorage.getItem("visit-vois-token");

axios.defaults.headers.common["Authorization"] = `Bearer ${encryptedToken}`;
axios.defaults.headers.common["X-Token-FingerPrint"] =
  await generateTokenFingerprint();


axios.interceptors.request.use(async (request) => {
  request.data = {
    payload: CryptoJS.AES.encrypt(
      JSON.stringify(request.data),
      "secret key 123"
    ).toString(),
  };
  return request;
});

axios.interceptors.response.use(
  (res) => {
    if (
      res.config.url.includes("/api/mosaicWall") ||
      res.config.url.includes("/api/eventassets") ||
      res.config.url.includes("/api/keys") ||
      res.config.url.includes("/api/stats/setpage")
    )
      return Promise.resolve(res);
    let decrypted = CryptoJS.AES.decrypt(res.data, "secret key 123").toString(
      CryptoJS.enc.Utf8
    );
    let decryptedItem = JSON.parse(decrypted); //JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

    res.data = decryptedItem;
    return Promise.resolve(res);
  },
  (error) => {
    const expectedErrors =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedErrors) {
    }
    let decrypted = CryptoJS.AES.decrypt(error.response.data, "secret key 123");

    let decryptedItem = decrypted.toString(CryptoJS.enc.Utf8);
    error.response.data = decryptedItem;

    if (decryptedItem == JSON.stringify("Concurrent User")) {
      document.getElementById("concurrentuserbtn").click();
      return Promise.reject(error);
    } else if (decryptedItem == JSON.stringify("Expired Token")) {
      document.getElementById("expiredtokenbtn").click();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export async function setJwt() {
  try {
    const tokenCred = await getCredentials();
    const tokenKey = `msal.${tokenCred.Client_ID}.idtoken`;

    const token = await getIdToken();
    if (token) {
      if (window.location.pathname !== "/admin/rounds") {
        const requestedPathname = localStorage.getItem("Requested");
        const qParams = localStorage.getItem("q");

        if (requestedPathname) {
          window.location.href = qParams
            ? `${requestedPathname}?${qParams}`
            : requestedPathname;
        } else {
          window.location.href = "/admin/rounds";
        }
      }
    }
  } catch (error) {
    console.error("Error in setJwt:", error);
  }
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  create: axios.create,
};
