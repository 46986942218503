import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { getCurentExternal } from "../apis/Round";
import { getCurrentRound } from "../apis/Round";
import { editMember } from "../apis/Users";
import { getToken } from "../apis/Users";
import { getCodebyMobile, verifyMobileCode } from "../apis/Verfication";
import amico from "../assets/amico.webp";
import sent from "../assets/sent.webp";
import ButtonRounded from "../components/ButtonRounded";
import BasicModal from "../components/ModalMessage";
import InputCode from "../components/OTPInputs";

const OTP = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [openMsg, setOpenMSg] = useState(false);
  let obj = location.state.obj ? location.state.obj : null;
  const [disabled, setDisabled] = useState(true);
  const [round, setRound] = useState();
  const [incorrect, setIncorrect] = useState(false);
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    getCurrentRound().then((res) => {
      setRound(res.data[0]);
    });
  }, []);

  useEffect(() => {
    if (disabled) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 1) {
            return prevCountdown - 1;
          } else {
            clearInterval(timer);
            setDisabled(false);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [disabled]);

  const verfyMobileCode = (code) => {
    verifyMobileCode(obj.mobileNO, code)
      .then((res) => {
        if (res.status == 200) {
          getCurentExternal().then((x) => {
            getToken({
              name: obj.name,
              mobileNO: obj.mobileNO,
              status: "Activated",
              roundID: x._id,
            }).then((res) => {
              sessionStorage.setItem("visit-vois-token", res.data);
              setOpenMSg(true);
            });
          });
        } else {
          setIncorrect(true);
        }
      })
      .catch((err) => setIncorrect(true));
  };

  const resendCode = () => {
    setDisabled(true);
    setCountdown(30);
    getCodebyMobile(location.state.obj.mobileNO).then((res) => {
    });
  };

  return (
    <Box sx={{ flexGrow: 1 }} my={1} alignItems='center'>
      {openMsg && (
        <BasicModal
          onClose={() => navigate("/thoughts")}
          img={<img width={"47px"} height={"47px"} src={sent} />}
          text='Your account is verified'
        />
      )}
      <Grid
        display={"flex"}
        flexDirection={"column"}
        rowGap={2}
        my={4}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid mt={6}>
          <img src={amico} />
        </Grid>
        <h4>Verify Your Profile</h4>
        <span>Enter the verification code sent to your phone</span>

        <Grid
          mx={2}
          my={2}
          alignItems={"center"}
          display={"flex"}
          textAlign={"center"}
          flexDirection={"column"}
          sx={{ rowGap: "20px" }}
        >
          <InputCode
            length={4}
            onComplete={(code) => {
              verfyMobileCode(code);
            }}
          />
          <Grid my={4}>
            {incorrect && (
              <span style={{ color: "#E60000" }}>Incorrect code!</span>
            )}
            <div style={{ marginBottom: "20px" }}>
              <span style={{ fontWeight: "bold" }}>Didn't get the code?</span>{" "}
              {disabled ? `retry in ${countdown} seconds` : ""}
            </div>
            <ButtonRounded
              value={!disabled}
              text={"Resend"}
              onClick={() => resendCode()}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OTP;
